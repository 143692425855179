@media print {
    thead.report-header .pageHeader {
        display: block !important;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

.space-between-table {
    margin-bottom: 15px;
}
thead.report-header {
    display: table-header-group;
    margin-top: 20px;
    margin-bottom: 20px;
}
thead.report-header .pageHeader {
    display: none;
}

table.report-container {
    page-break-after: always;
    width: 100%;
}

table.report-container .main {
    padding: 10px;
}

tfoot.report-footer {
    display: table-footer-group;
}

.page-content-inside-always {
    page-break-after: always;
    page-break-inside: avoid;
}

.page-content-inside-auto {
    page-break-after: auto;
    page-break-inside: avoid;
}

.container-table-1,
.container-table-2,
.container-table-3,
.container-table-4 {
    margin-bottom: 10px;
}

.main table,
.main tr,
.main td,
.main th {
    border: 0;
    padding: 0 !important;
    border-width: 0;
    border-style: none;
    border-collapse: collapse;
    font-size: 6.5pt !important;
    line-height: 1.5 !important;
}

.container-table-1 table thead,
.container-table-2 table thead,
.container-table-3 table thead,
.container-table-4 table thead {
    border-top: 1px solid #eaeaea;
}

.container-table-1 table thead tr,
.container-table-2 table thead tr,
.container-table-3 table thead tr,
.container-table-4 table thead tr {
    border-left: 1px solid #eaeaea;
}

.container-table-1 table thead tr th,
.container-table-2 table thead tr th,
.container-table-3 table thead tr th,
.container-table-4 table thead tr th {
    border-right: 1px solid #eaeaea;
}

.container-table-1 table tbody,
.container-table-2 table tbody,
.container-table-3 table tbody,
.container-table-4 table tbody {
    border-bottom: 1px solid #eaeaea;
}

.container-table-1 table tbody tr,
.container-table-2 table tbody tr,
.container-table-3 table tbody tr,
.container-table-4 table tbody tr {
    border-top: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
}
.container-table-1 table tbody tr td,
.container-table-2 table tbody tr td,
.container-table-3 table tbody tr td,
.container-table-4 table tbody tr td {
    border-right: 1px solid #eaeaea;
    text-align: right;
}

.container-table-1 table tbody tr td:first-child,
.container-table-2 table tbody tr td:first-child,
.container-table-3 table tbody tr td:first-child,
.container-table-4 table tbody tr td:first-child {
    background-color: #fafafa;
}
.container-table-4 {
    display: flex;
}
.container-table-4 .columm-1,
.container-table-4 .columm-2,
.container-table-4 .columm-3 {
    width: 25%;
    margin-right: 1%;
}

.container-table-4 .columm-4 {
    width: 25%;
}
.container-table-4 .columm-4 .table-bonus {
    display: flex;
    flex-direction: row;
}

.container-table-4 .columm-4 .td-bonus {
    font-size: 6.5pt !important;
    line-height: 1.5 !important;
    width: 50%;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    text-align: right;
}

.container-table-4 .columm-4 .td-bonus:first-child {
    border-left: 1px solid #eaeaea;
    background-color: #fafafa;
}
